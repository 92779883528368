/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react"
import axios from "axios"
import { DateTime } from "luxon"

export const useLast24HoursTokenPriceData = () => {

  const [last24hoursPricingData, setLast24hoursPricingData] = useState(null)
  const [isLoading, setLoading] = useState(false)

  const fetchLast24HoursTokenPriceDataFromAPI = async () => {
    try {
      const endpoint = `${process.env.REACT_APP_COINGECKO_ENDPOINT}coins/everearn/market_chart?vs_currency=usd&days=7&interval=daily`
      const response = await axios.get(endpoint)
      if (response.status === 200) {
        const dataSet = response.data
        const graphData = []
        if (dataSet.prices && dataSet.prices.length > 0) {
          // eslint-disable-next-line array-callback-return
          dataSet.prices.map((item) => {
            const dateTimeReadable = DateTime.fromMillis(item[0]).toFormat("yy-MM-dd HH:mm")
            const payload = {
              name: dateTimeReadable,
              uv: item[1]
            }
            graphData.push(payload)
          })
        }
        setLast24hoursPricingData(graphData)
      } else {
        setLast24hoursPricingData(null)
      }
    } catch (error) {
      setLast24hoursPricingData(null)
      console.error("ERROR while fetching token data form API ", error)
    }
  }


  useEffect(async () => {

    try {
      setLoading(true)
      //get user token holdings by address
      await fetchLast24HoursTokenPriceDataFromAPI()
    } catch (error) {
      setLast24hoursPricingData(null)
      console.error('error while fetching common token info ', error.message || '')
    } finally {
      setLoading(false)
    }

  }, [])


  return { last24hoursPricingData, isLoading }
}

export default useLast24HoursTokenPriceData

