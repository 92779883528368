import React from 'react'
import './HeaderComponent.css'
import Logo from '../../images/everearn-logo.png'

// If user is not logged in, this header will appear
function LoginHeader() {
    return (
        <nav className="navbar navbar-dark" style={{ borderBottom: '2px solid' }}>
            <a className="navbar-brand" style={{ marginLeft: '15px' }}><img src={Logo} style={{ width: '150px' }} /></a>
        </nav>
    )
}

export default LoginHeader
