// ** Reactstrap Imports
import { Row, Col, Card, CardBody, InputGroup, Input } from 'reactstrap';
import { useEffect, useState } from 'react';
import { Select, Button, notification, Image } from 'antd';
import './NftMint.css';
import { FileUploader } from "react-drag-drop-files";
import defaultPreviewImage from '../../images/nft-preview-placeholder.png'
import { BgColorsOutlined } from '@ant-design/icons';
import { configs } from '../../blockchain/web3.config';
import { DateTime } from 'luxon';
import axios from 'axios';
import * as web3service from '../../blockchain/web3.service'
import { useWeb3React } from '@web3-react/core';
import ReactPlayer from 'react-player'
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';

const NftMinting = () => {

	const imageTypes = ["JPG", "PNG", "GIF", "JPEG"];
	const videoTypes = ["MP4"]
	const audioTypes = ["MP3", "AAC", "WAV"]
	const _3dTypes = ["OBJ", "CSO", "VS", "HXN", "FX"]

	const [file, setFile] = useState(null)
	const [name, setName] = useState('')
	const [description, setDescription] = useState("")
	const [nftImagePreview, setNftImagePreview] = useState('')
	const [uploadFileType, setUploadFileType] = useState(imageTypes)
	const [fileType, setFileType] = useState('');
	const [isNFTMintingLoading, setIsNFTMintingLoading] = useState(false)
	const [categories, setCategories] = useState('')
	const { Option } = Select;
	const { account, library } = useWeb3React()

	const handleSelectChange = (value) => {
		setCategories(value)
	}

	const resetForm = () => {
		setName('')
		setDescription('')
		setCategories(null)
		setFile(null)
	}

	const provideAssetContainer = (assetURI, assetType) => {
		if (fileType.includes('image')) {
			return (<Image
				src={nftImagePreview}
				preview={false}
				fallback={defaultPreviewImage}
			/>)
		}
		if (fileType.includes('video')) {
			return (
				<div className='player-wrapper'>
					<ReactPlayer
						className='react-player'
						playing
						width='100%'
						height='100%'
						controls
						url={[
							{ src: assetURI, type: assetType },
						]}
					/>
				</div>)
		}
		if (fileType.includes('audio')) {
			return (<AudioPlayer
				autoPlay
				src={assetURI}
			/>)
		}
	}

	const uploadFileToIPFS = async (file) => {
		const { web3Config } = configs
		const endpoint = web3Config.pinataIPFSServer + 'pinning/pinFileToIPFS'
		const pinataAPIKey = process.env.REACT_APP_PINATA_API_KEY
		const pinataAPISecret = process.env.REACT_APP_PINATA_SECRET_KEY

		const dt = DateTime.now()
		const newFileName = dt.toMillis() + "_" + file.name
		let data = new FormData();
		data.append('file', file);

		const metadata = JSON.stringify({
			name: newFileName,
			keyvalues: {
				everEarnMinterFile: 'ever-earn-minter' + newFileName,
				originalFileName: file.name,
			}
		});

		data.append('pinataMetadata', metadata);
		const response = await axios.post(endpoint, data, {
			maxBodyLength: 'Infinity', //this is needed to prevent axios from erroring out with large files
			headers: {
				'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
				pinata_api_key: pinataAPIKey,
				pinata_secret_api_key: pinataAPISecret
			}
		})

		if (response.status === 200) {
			notification['success']({
				message: 'File has been upload to the IPFS server',
				description: 'Your NFT File has been uploaded to IPFS server successfully',
			});
			return response.data.IpfsHash

		} else {
			notification['danger']({
				message: 'Error while uploading file',
				description: 'Oppz!, something went wrong while uploading file to IPFS server, please try again',
			});
			return null
		}
	}

	const createMetaDataFile = (IPFSHash) => {

		const timestamp = DateTime.now();
		const { ts } = timestamp;
		const fileName = `ever-earn-metadata-${ts}.json`;

		const metadata = {
			name: fileName
		}

		const content = {
			name: name,
			description: description,
			image: "ipfs://" + IPFSHash,
			category: categories ? categories.toString() : '',
			fileType: fileType,
			attributes: [],
		}
		const payload = {
			pinataMetadata: metadata,
			pinataContent: content,
		}

		return payload;
	}

	const uploadMetaDataFile = async (metaDataJson) => {
		try {
			const { web3Config } = configs
			const endpoint = web3Config.pinataIPFSServer + 'pinning/pinJSONToIPFS'
			const options = {
				headers: {
					pinata_api_key: process.env.REACT_APP_PINATA_API_KEY,
					pinata_secret_api_key: process.env.REACT_APP_PINATA_SECRET_KEY
				}
			}
			const response = await axios.post(endpoint, metaDataJson, options);
			notification['success']({
				message: 'Metadata file has been uploaded',
				description: 'Metadata file has been upload to the IPFS server',
			});
			return response.data;
		} catch (error) {
			console.error("metadata upload error", error);
			notification['danger']({
				message: 'ERROR occurred while uploading',
				description: 'Oppz! Something went wrong while uploading metadata file to IPFS server',
			});
		}
	}

	const handleNFTMinting = async () => {
		try {
			setIsNFTMintingLoading(true)
			if (file == null) {
				notification['warning']({
					message: 'Please fill the required felids',
					description: 'Please upload the asset to create a NFT',
				});
				return
			}

			if (name === '') {
				notification['warning']({
					message: 'Please fill the required felids',
					description: 'Please give the name to your NFT this will help other to identify your NFT',
				});
				return
			}

			if (description === '') {
				notification['warning']({
					message: 'Please fill the required felids',
					description: 'Please tell us more about your NFT by providing meaningful description about your NFT',
				});
				return
			}

			if (categories === '') {
				notification['warning']({
					message: 'Please fill the required felids',
					description: 'Please select a atleast one category for your nft',
				});
				return
			}

			//upload file to IPFS server
			const IPFSHash = await uploadFileToIPFS(file)
			//create metadata file
			const metadataPayload = createMetaDataFile(IPFSHash)
			const metadataResponse = await uploadMetaDataFile(metadataPayload)
			const metaDataURI = "ipfs://" + metadataResponse.IpfsHash;

			const response = await web3service.mintNFT(library.getSigner(), account, metaDataURI)
			console.log('response', response)
			resetForm()
			notification['success']({
				message: 'Congratulation',
				description: 'You have successfully minted your NFT',
			});
		} catch (error) {
			setIsNFTMintingLoading(false)
			console.error("ERROR while submit NFT to for minting ", error)
			const errorMessage = error ? error.message : 'Please check the required felids and your account balance to proceed'
			notification['warning']({
				message: 'Error occurred while trying to mint you NFT',
				description: errorMessage,
			});
		} finally {
			setIsNFTMintingLoading(false)
		}

	}

	useEffect(() => {
		setFileType('image')
	}, [])

	const handleChange = async (file) => {

		if (file) {
			setFileType(file.type)
			setFile(file)
			const nftImage = URL.createObjectURL(file)
			setNftImagePreview(nftImage)
		} else {
			setFile(null);
		}
	};

	const setSelectFileType = (value) => {

		setFileType(value)
		if (value === 'image') {
			setUploadFileType(imageTypes)
		}

		if (value === 'video') {
			setUploadFileType(videoTypes)
		}

		if (value === 'music') {
			setUploadFileType(audioTypes)
		}

		if (value === '3d') {
			setUploadFileType(_3dTypes)
		}
	};

	return (

		<div className="container mt-4 p-4 nft-minting-container">
			<Row>
				<Col>
					<Card style={{ border: 'none' }} className="customcard p-4">
						<div className='nft-minting-input-labels'>
							<h6 className="text-muted">Select File Type</h6>
						</div>

						<Row>
							<Col sm="3" md="3" lg="3">
								<Card
									className={fileType === 'image' ? 'selected-box' : 'mint-box'}
									onClick={() => setSelectFileType('image')}
								>
									<CardBody>Image</CardBody>
								</Card>
							</Col>
							<Col sm="3" md="3" lg="3">
								<Card
									className={fileType === 'video' ? 'selected-box' : 'mint-box'}
									onClick={() => setSelectFileType('video')}
								>
									<CardBody>Video</CardBody>
								</Card>
							</Col>
							<Col sm="3" md="3" lg="3">
								<Card
									className={fileType === 'music' ? 'selected-box' : 'mint-box'}
									onClick={() => setSelectFileType('music')}
								>
									<CardBody>Music</CardBody>
								</Card>
							</Col>
							<Col sm="3" md="3" lg="3">
								<Card
									className={fileType === '3d' ? 'selected-box' : 'mint-box'}
									onClick={() => setSelectFileType('3d')}
								>
									<CardBody>3D</CardBody>
								</Card>
							</Col>
						</Row>

						<Row>
							<Col sm="12" md="8" lg="8">
								<div className='nft-minting-input-labels mt-4'>
									<h6 className="text-muted">Upload your asset</h6>
								</div>

								<div className='upload-asset-container'>
									<div className="mt-4 d-flex col-md-6 col-lg-12 col-sm-12 justify-content-center custom_upload_area">
										<FileUploader
											classes="file-upload"
											handleChange={handleChange}
											name="file"
											maxSize={30}
											types={uploadFileType}
										/>
									</div>
								</div>

								<div className='nft-minting-input-labels mt-4'>
									<h6 className="text-muted">NFT Name: </h6>
									<InputGroup className="input-group-merge">
										<Input type="text" name="Name" placeholder="Enter Name" value={name}
											onChange={e => setName(e.target.value)} className='nft-input' />
									</InputGroup>
								</div>

								<div className='nft-minting-input-labels mt-4'>
									<h6 className="text-muted">Select NFT Categories: </h6>
									<Select
										mode="multiple"
										allowClear
										style={{ width: '100%' }}
										dropdownStyle={{ backgroundColor: '#F9B800' }}
										placeholder="Max 3 categories"
										defaultValue={[]}
										onChange={handleSelectChange}
										className='nft-input'
									>
										<Option value="art" label="Art" />
										<Option value="cartoon" label="Cartoon" />
										<Option value="music" label="Music" />
										<Option value="3d" label="3D" />
									</Select>
								</div>

								<div className='nft-minting-input-labels mt-4'>
									<h6 className="text-muted">NFT Description: </h6>
									<Input
										type="textarea"
										name="text"
										id="exampleText"
										rows="3"
										placeholder="Description"
										value={description}
										onChange={e => setDescription(e.target.value)} className='nft-input'
									/>
								</div>

								<div className='mint-nft-btn-container mt-4'>
									<Button
										onClick={handleNFTMinting}
										type="primary"
										loading={isNFTMintingLoading}
										icon={<BgColorsOutlined />}
										style={{ background: '#efbc00', borderColor: '#efbc00', color: '#000' }}
									>
										Mint Your NFT
									</Button>
								</div>
							</Col>

							<Col sm="12" md="4" lg="4">
								<div className='nft-minting-input-labels mt-4'>
									<h6 className="text-muted">NFT Preview</h6>
								</div>
								<div className='nft-preview-container'>
									<div className="nft-image-preview nft-asset-container-section">
										{provideAssetContainer(nftImagePreview, fileType)}
									</div>

									<div className='nft-metadata-container'>
										<div className="nft-name">
											{name ? name : 'Name your cool NFT'}
										</div>
										<div className="nft-categories"></div>
										<div className="nft-description">
											{description ? description : 'Tell us more about your NFT'}
										</div>
									</div>
								</div>

							</Col>
						</Row>
					</Card>
				</Col>
			</Row>
		</div>

	);
};

export default NftMinting;
