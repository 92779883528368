import React, { useEffect, useState, useContext } from 'react'
import { Link } from 'react-router-dom'
import './HeaderComponent.css'
import Logo from '../../images/everearn-logo.png'
import Metamask from '../../images/metamask.png'
import WalletConnect from '../../images/wallet-connect.png'
import { getEllipsisTxt } from '../../Helpers/Formatters'
import { Modal, notification, Drawer } from 'antd';
import { Copy, ExternalLink, CheckCircle } from 'react-feather'
import { useWeb3React } from '@web3-react/core'
import { InjectedConnector } from '@web3-react/injected-connector'
import { WalletConnectConnector } from '@web3-react/walletconnect-connector'
import * as web3Service from '../../blockchain/web3.service'
import NumberFormat from "react-number-format";
import { GlobalState } from '../../GlobalState'
import { Menu, Button, Space } from 'antd';
import {
  MenuOutlined, CloseOutlined
} from '@ant-design/icons';

function HeaderComponent() {

  const state = useContext(GlobalState)
  const [isModalVisible, setIsModalVisible] = state.isModalVisible
  const [isDisconnectVisible, setIsDisconnectVisible] = state.isDisconnectVisible
  const [isLoginLoading, setIsLoginLoading] = useState(false)
  const [yourBNBBalance, setYourBNBBalance] = useState(0.00)
  const [addressCopy, setAddressCopy] = useState(false)
  const [visible, setVisible] = useState(false);

  const web3React = useWeb3React()
  const { account, deactivate } = useWeb3React()


  const openNotificationWithIcon = (type, topic, message) => {
    notification[type]({
      message: topic,
      description:
        message,
    });
  };

  useEffect(() => {
    const fetchBnbBalance = async () => {
      const bnbBalance = await web3Service.getBnbBalanceOfAddress(account)
      setYourBNBBalance(bnbBalance || 0.00)
    }
    fetchBnbBalance()
  }, [account])

  const showModal = () => {
    setIsModalVisible(true)
    setVisible(false)
  };

  const showDisconnectModal = () => {
    setIsDisconnectVisible(true)
    setVisible(false)
  }

  const handleOk = () => {
    setIsDisconnectVisible(false)
    setIsModalVisible(false)
  };

  const handleCancel = () => {
    setIsDisconnectVisible(false)
    setIsModalVisible(false)
  };

  const POLLING_INTERVAL = 3000
  const injected = new InjectedConnector({ supportedChainIds: [56, 97, 1] })
  const wcConnector = new WalletConnectConnector({
    rpc: {
      56: "https://bsc-dataseed1.binance.org"
    },
    network: "binance",
    qrcode: true,
    chainId: 56,
    qrcodeModalOptions: {
      mobileLinks: [
        "metamask",
        "trust"
      ]
    },
    pollingInterval: POLLING_INTERVAL
  })
  wcConnector.networkId = 56

  const connectWithMetamask = async () => {
    try {
      setIsLoginLoading(true)
      await web3React.activate(injected)
      const loginPayload = {
        provider: 'injected',
        isLoggedIn: true
      }
      window.localStorage.setItem('userData', JSON.stringify(loginPayload))
    } catch (error) {
      const loginPayload = {
        provider: 'injected',
        isLoggedIn: false
      }
      window.localStorage.setItem('userData', JSON.stringify(loginPayload))
      console.error('error connecting to metamask ', error)
    } finally {
      setIsLoginLoading(false)
      setIsModalVisible(false)
    }
  }

  const connectWithWalletConnect = async () => {
    try {
      console.log("wallet connecto statres")
      setIsLoginLoading(true)
      await web3React.activate(wcConnector)
      const loginPayload = {
        provider: 'walletconnect',
        isLoggedIn: true
      }
      window.localStorage.setItem('userData', JSON.stringify(loginPayload))
      console.log("wallet connect end")
    } catch (error) {
      const loginPayload = {
        provider: 'walletconnect',
        isLoggedIn: false
      }
      window.localStorage.setItem('userData', JSON.stringify(loginPayload))
      console.error('error connecting to wallet-connect ', error)
    } finally {
      setIsLoginLoading(false)
      setIsModalVisible(false)
    }
  }

  const handleDisconnect = async () => {
    setVisible(false);
    try {
      window.localStorage.removeItem("userData")
      deactivate()
      handleCancel()
    } catch (error) {
      console.error("error while logout from user ", error)
    }
  }

  const copyAddress = () => {
    setAddressCopy(true)
    navigator.clipboard.writeText(account)
  }

  const showDrawer = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };

  return (

    <nav className="navbar navbar-dark">
      <a className="navbar-brand" style={{ marginLeft: '15px' }}><img src={Logo} style={{ width: '30px' }} className='mr-3' />  EverEarn</a>

      {/* <Menu style={{ color: 'white', marginRight: '20px' }} className="navbar-mobile" onClick={setVisible} /> */}
      {/* <MenuOutlined  onClick={showDrawer} /> */}
      <Button type="primary" onClick={showDrawer} className='mobile-drawer'>
        <MenuOutlined onClick={showDrawer} />
      </Button>
      <div className="d-flex justify-content-center navbar-pc" style={{ position: 'relative', marginTop: '-25px' }}>
        <li className="nav-item">
          <Link className="nav-link hover-underline-animation" to="/">Dashboard</Link>

        </li>
        <li className="nav-item">
          <Link className="nav-link hover-underline-animation" to="/calculator">Calculator</Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link hover-underline-animation" to="/nft-mint">NFT Minting</Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link hover-underline-animation" to="/staking">Staking</Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link hover-underline-animation" to="/nft-tracker">NFT Tracker</Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link hover-underline-animation" to="/wallet-tracker">Wallet Tracker</Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link hover-underline-animation" to="/faq">FAQ</Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link hover-underline-animation" to="/to-do">To Do</Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link hover-underline-animation" to="/ever-swap">EverSwap</Link>
        </li>
      </div>
      {/* <Menu mode="horizontal">
        <Menu.SubMenu key="SubMenu" title="ADDONS" >
          <Menu.ItemGroup title="Item 1">
            <Menu.Item key="app">
              <li className="nav-item">
                <Link className="nav-link hover-underline-animation" to="/faq">FQA</Link>
              </li>
            </Menu.Item>
          </Menu.ItemGroup>
        </Menu.SubMenu>
      </Menu> */}

      {
        account ? (
          <>
            {/* If user is viewing from pc, this section will appear */}
            <div className="btn-group navbar-pc">
              <label className="btn btn-green" style={{ fontSize: '14px' }}>
                <NumberFormat
                  thousandsGroupStyle="thousand"
                  value={yourBNBBalance}
                  decimalSeparator="."
                  displayType="text"
                  suffix='BNB'
                  type="text"
                  thousandSeparator={true}
                  allowNegative={true}
                  decimalScale={6} />
              </label>
              <label className="btn connect-btn" style={{ marginRight: '15px', fontSize: '14px' }} onClick={showDisconnectModal}>
                <img src={Logo} style={{ width: '20px', marginRight: '5px' }} />{getEllipsisTxt(account, 7)}
              </label>
            </div>

            {/* If user is viewing from mobile, this section will appear */}
            <button className="btn navbar-pc hide-on-pc connect-btn my-2 my-sm-0" style={{ marginRight: '15px' }} onClick={showDisconnectModal}><img src={Logo} style={{ width: '20px', marginRight: '5px' }} />{getEllipsisTxt(account, 4)}</button>
          </>
        ) :
          <button className="btn connect-btn col-lg-1 col-md-2 col-sm-4 my-2 my-sm-0 navbar-pc" style={{ marginRight: '15px', fontSize: '14px' }} onClick={showModal}>Connect</button>
      }


      {/* Modal to handle connect wallet options */}
      <Modal title="Connect to wallet" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel} footer={null} >
        <button className="btn btn-orange btn-lg col-12 fw-bold text-start text-light mb-2 connect-model-button" onClick={connectWithMetamask}>
          <img src={Metamask} style={{ width: '25px', marginTop: '-5px', marginRight: '10px' }} />
          Metamask
        </button>
        <button className="btn btn-orange btn-lg col-12 fw-bold text-start text-light mb-2 connect-model-button" onClick={connectWithWalletConnect}>
          <img src={WalletConnect} style={{ width: '25px', marginTop: '-5px', marginRight: '10px' }} />
          WalletConnect
        </button>
      </Modal>


      {/* Modal visible after clicking on wallet button, which will handle wallet disconnet */}
      <Modal title="Connect to wallet" visible={isDisconnectVisible} onOk={handleOk} onCancel={handleCancel} footer={null}>

        <div className="row">
          <p className="text-muted fw-bold col-9 hide-on-mobile">Connected with Metamask</p>
          <p className="text-end col-md-3 col-sm-6 fw-bold text-green" onClick={handleDisconnect}>Disconnect</p>
        </div>

        <button className="btn btn-lg btn-orange col-12 text-light fw-bold text-start mb-2 connect-model-button" style={{ fontSize: '16px' }}><img src={Logo} style={{ width: '20px', marginRight: '15px' }} />{getEllipsisTxt(account, 7)}</button>

        <div className="row mt-3">
          {!addressCopy && <p className="col-lg-4 col-md-6 col-sm-6 text-green fw-bold" onClick={copyAddress}><Copy size={18} color="rgba(45,100,206)" /><span style={{ marginLeft: '5px' }}>Copy address</span></p>}
          {addressCopy && <p className="col-lg-4 col-md-6 col-sm-6 text-green fw-bold"><CheckCircle size={18} color="rgba(45,100,206)" /><span style={{ marginLeft: '5px' }}>Copied</span></p>}
          <a href={`https://bscscan.com/address/${account}`} target="_blank" className="col-lg-4 col-md-6 col-sm-6 text-green fw-bold" rel="noreferrer"><ExternalLink size={18} color="rgba(45,100,206)" /><span style={{ marginLeft: '5px' }}>View on explorer</span></a>
        </div>

      </Modal>

      <Drawer placement="left" onClose={onClose} visible={visible} width="250px" extra={
        <Space>
          <div className='drawer-image'>
            <img src={Logo} style={{ width: '30px' }} className='mr-3' />
          </div>
          <div className='drawer-close' onClick={onClose}>
            <CloseOutlined />
          </div>
        </Space>
      }>
        <div className="btn btn-block btn-orange col-12">
          <Link className="nav-link" to="/" onClick={onClose}>Dashboard</Link>
        </div>

        <div className="btn btn-block btn-orange col-12 mt-3">
          <Link className="nav-link" to="/calculator" onClick={onClose}>Calculator</Link>
        </div>

        <div className="btn btn-block btn-orange col-12 mt-3">
          <Link className="nav-link" to="/nft-mint" onClick={onClose}>NFT Minting</Link>
        </div>
        <div className="btn btn-block btn-orange col-12 mt-3">
          <Link className="nav-link" to="/staking" onClick={onClose}>Staking</Link>
        </div>
        <div className="btn btn-block btn-orange col-12 mt-3">
          <Link className="nav-link" to="/nft-tracker" onClick={onClose}>NFT Tracker</Link>
        </div>
        <div className="btn btn-block btn-orange col-12 mt-3">
          <Link className="nav-link" to="/wallet-tracker" onClick={onClose}>Wallet Tracker</Link>
        </div>
        <div className="btn btn-block btn-orange col-12 mt-3">
          <Link className="nav-link" to="/faq">FAQ</Link>
        </div>
        <div className="btn btn-block btn-orange col-12 mt-3">
          <Link className="nav-link" to="/to-do" onClick={onClose}>To Do</Link>
        </div>
        <div className="btn btn-block btn-orange col-12 mt-3">
          <Link className="nav-link" to="/ever-swap" onClick={onClose}>EverSwap</Link>
        </div>

        <hr />

        <div className="mt-3">
          {
            account ?
              <button className="btn connect-btn col-12" onClick={showDisconnectModal}><img src={Logo} style={{ width: '20px', marginRight: '5px' }} />{getEllipsisTxt(account, 4)}</button> :
              <button className="btn connect-btn col-12" onClick={showModal}>Connect</button>
          }
        </div>
      </Drawer>
    </nav>

  )
}

export default HeaderComponent
