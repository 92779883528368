import { BrowserRouter as Router } from 'react-router-dom'
import React, { useLayoutEffect } from 'react'
import {DataProvider} from './GlobalState'
import './App.css';
import LayoutView from './Pages/LayoutView';
import 'bootstrap/dist/js/bootstrap'
import { configs } from './blockchain/web3.config'
import Web3 from "web3"

function App() {

  useLayoutEffect(() => {
    if (!window.web3Instance) {
      const { web3Config } = configs
      //initiate web3 instance globally 
      const httpProvider = new Web3.providers.HttpProvider(web3Config.webRpcHttpNode)
      const web3 = new Web3(httpProvider)
      window.web3Instance = web3
    }
  }, [])

  return (
    <DataProvider>
      <Router>
        <div className="App">
          <LayoutView />
        </div>
      </Router>
    </DataProvider>
  );
}

export default App;
