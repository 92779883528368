import React, { useEffect, useState } from 'react'
import { stakingConfigs } from '../services/staking.config'
import { ethers } from "ethers";

export default function StakingGuideline(props) {

  const { contractAddress } = props
  const [maxStakingPercentage, setMaxStakingPercentage] = useState(0.0)
  const [penaltyPercentage, setPenaltyPercentage] = useState(0.0)

  useEffect(() => {
    getMaxStakingPercentage()
    getPenaltyPercentage()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getMaxStakingPercentage = async () => {
    try {

      const stakingContractABI = JSON.parse(stakingConfigs.stakingContractAbi);
      //get current metamask provider
      const provider = new ethers.providers.JsonRpcProvider(stakingConfigs.webRpcHttpNode, { name: 'binance', chainId: 56 });
      const stakingContractInstance = new ethers.Contract(contractAddress, stakingContractABI, provider);
      const maxStakingResponse = await stakingContractInstance.maxStakePercentage()
      const maxStakingAmountInt = parseInt(maxStakingResponse.toString())
      setMaxStakingPercentage(maxStakingAmountInt)

    } catch (error) {
      setMaxStakingPercentage(0.0)
      console.error("ERROR while fetching max staking percentage from the contract ", error)
    }
  }

  const getPenaltyPercentage = async () => {
    try {

      const stakingContractABI = JSON.parse(stakingConfigs.stakingContractAbi);
      //get current metamask provider
      const provider = new ethers.providers.JsonRpcProvider(stakingConfigs.webRpcHttpNode, { name: 'binance', chainId: 56 });
      const stakingContractInstance = new ethers.Contract(contractAddress, stakingContractABI, provider);
      const penaltyPercentageResponse = await stakingContractInstance.penaltyPercentage()
      const penaltyPercentageInt = parseInt(penaltyPercentageResponse.toString())
      setPenaltyPercentage(penaltyPercentageInt)

    } catch (error) {
      setPenaltyPercentage(0.0)
      console.error("ERROR while fetching penalty percentage from the contract ", error)
    }
  }

  return (
    <div>
      <div className="staking-guide-container mt-3">
        <div className="staking-definition-title">
          Staking Process
        </div>
        <span className="staking-guide">
          Investors must hold a minimum of $20 USD worth of EverEarn to participate in BUSD Staking.
        </span><br />
        <span className="staking-guide">
          Investors can invest up to {maxStakingPercentage}% of their total EverEarn holding value (USD value), up to a maximum of 1,000 BUSD in EACH of the staking pools.
        </span><br />
        <span className="staking-guide">
          Early withdrawal forfeits any earned interest.
        </span><br />
      </div>
    </div>
  )
}
