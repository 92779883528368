import React, { useContext } from 'react'
import { Layout } from 'antd';
import Pages from './Pages'
import HeaderComponent from '../Components/Header/HeaderComponent';
import SiderComponent from '../Components/Sider/SiderComponent';
import LoginHeader from '../Components/Header/LoginHeader';
import { GlobalState } from '../GlobalState';
import { FacebookOutlined, TwitterOutlined, InstagramOutlined, GithubOutlined, YoutubeOutlined, RedditOutlined, MediumOutlined } from '@ant-design/icons';
function LayoutView() {

    const { Content, Footer } = Layout;
    const currentYear = new Date().getFullYear()

    return (

        // this is the main layout of the website, it contains a Header, Side menu and a Footer
        <Layout>

            {

                <HeaderComponent />
            }

            <Content>
                <Layout className="site-layout-background">

                    <Content>
                        <div style={{ minHeight: '100vh' }}>
                            <Pages />
                        </div>

                        <Footer style={{ textAlign: 'center' }}>
                            <div className='d-flex row'>
                                <div className='d-flex justify-content-center align-items-center mb-1'>
                                    <a href='https://www.facebook.com/EverEarn-106688511903307/' target={'_blank'} rel="noreferrer">
                                        <FacebookOutlined style={{ color: 'white' }} />
                                    </a>
                                    <a href='https://twitter.com/theEverEarn' target={'_blank'} rel="noreferrer">
                                        <TwitterOutlined style={{ color: 'white', marginLeft: '10px' }} />
                                    </a>
                                    <a href='https://www.instagram.com/everearnofficial/' target={'_blank'} rel="noreferrer">
                                        <InstagramOutlined style={{ color: 'white', marginLeft: '10px' }} />
                                    </a>
                                    <a href='https://github.com/EverEarn/EverEarnProject' target={'_blank'} rel="noreferrer">
                                        <GithubOutlined style={{ color: 'white', marginLeft: '10px' }} />
                                    </a>
                                    <a href='https://www.youtube.com/channel/UCFx-WBuUIYAzpRq9TG4eMuw' target={'_blank'} rel="noreferrer">
                                        <YoutubeOutlined style={{ color: 'white', marginLeft: '10px' }} />
                                    </a>
                                    <a href='https://www.reddit.com/r/EverEarn/' target={'_blank'} rel="noreferrer">
                                        <RedditOutlined style={{ color: 'white', marginLeft: '10px' }} />
                                    </a>
                                    <a href='https://everearn.medium.com/' target={'_blank'} rel="noreferrer">
                                        <MediumOutlined style={{ color: 'white', marginLeft: '10px' }} />
                                    </a>
                                </div>
                                <span className="text-light">Copyright © EverEarn {currentYear} </span>
                            </div>
                        </Footer>
                    </Content>
                </Layout>
            </Content>
        </Layout>
    )
}

export default LayoutView
