/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Row, Col, Card, CardBody, InputGroup, Input } from 'reactstrap';
import Staking7DaysPool from "./Staking7DaysPool";
import Staking30DaysPool from "./Staking30DaysPool";
import Staking60DaysPool from "./Staking60DaysPool";
import Staking90DaysPool from "./Staking90DaysPool";
import './Staking.css'
import { useWeb3React } from '@web3-react/core'
import StakingGuideline from'./components/StakingGuideline'
import { stakingConfigs } from './services/staking.config'

function StakingPools() {

  const { account } = useWeb3React()
  const [stakingAmountFor7Days, setStakingAmountFor7Days] = useState(0)
  const [stakingAmountFor30Days, setStakingAmountFor30Days] = useState(0)
  const [stakingAmountFor60Days, setStakingAmountFor60Days] = useState(0)
  const [stakingAmountFor90Days, setStakingAmountFor90Days] = useState(0)

  return (

    <div className="container mt-4 p-4 staking-container">
      <Row>
        <Col>
          <Card style={{ border: 'none' }} className="customcard p-4">

            <Row>
              <Col sm="12" md="6" lg="4">
                <div className="staking-definition-container apy-definition">
                  <div className="staking-definition-title">
                    Staking Definitions
                  </div>
                  <div className="definition-item">
                    <span className="glossary">APR</span> = Annual Percent Rate. Annual rate of return WITHOUT compounding.
                  </div>

                  <div className="definition-item">
                    <span className="glossary">APY</span> = Annual Percent Yield. Annual rate of return WITH compounding.
                  </div>

                </div>
              </Col>
              <Col sm="12" md="6" lg="8">
                <div className="staking-definition-container payout-definition">
                  <div className="staking-definition-title">
                    Staking Payout Details
                  </div>
                  <Row>
                    <Col sm="6">
                      <div className="definition-item">
                        <span className="glossary">7 Days</span> = 25% APR = 0.4759% for 1 cycle = 28.2% APY.
                      </div>
                      <div className="definition-item">
                        <span className="glossary">30 Days</span> = 30% APR = 2.4658% for 1 cycle = 34.0% APY.
                      </div>
                    </Col>
                    <Col sm="6">
                      <div className="definition-item">
                        <span className="glossary">60 Days</span> = 40% APR = 6.5753% for 1 cycle = 46.5% APY.
                      </div>
                      <div className="definition-item">
                        <span className="glossary">90 Days</span> = 50% APR = 12.3288% for 1 cycle = 59.2% APY.
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>

            <Row>
              <Col sm="12" md="6" lg="6">
                <div className="staking-definition-container apy-definition investor-details">
                  <StakingGuideline contractAddress={stakingConfigs.staking7DaysContractAddress} />
                </div>
              </Col>
              <Col sm="12" md="6" lg="6">
                <div className="staking-definition-container apy-definition investor-details">
                  <div className="staking-definition-title">
                    Staking Steps
                  </div>
                  <div className="definition-item">
                    <span className="glossary">1) </span> Enter amount of BUSD to stake
                  </div>

                  <div className="definition-item">
                    <span className="glossary">2) </span> Click on 'Approve BUSD Transfer' (Metamask will open for you to approve a small fee for access to contract and wallet)
                  </div>

                  <div className="definition-item">
                    <span className="glossary">3) </span> Click on 'Stake BUSD' (Metamask will open for you to approve a small fee to complete joining of staking)
                  </div>

                </div>
              </Col>
            </Row>

            <Row>
              <Col sm="12" md="12" lg="12">
                <Staking7DaysPool
                  walletAddress={account}
                  stakingAmount={stakingAmountFor7Days}
                  setStakingAmount={setStakingAmountFor7Days}
                />
                <Staking30DaysPool
                  walletAddress={account}
                  stakingAmount={stakingAmountFor30Days}
                  setStakingAmount={setStakingAmountFor30Days}
                />

                <Staking60DaysPool
                  walletAddress={account}
                  stakingAmount={stakingAmountFor60Days}
                  setStakingAmount={setStakingAmountFor60Days}
                />

                <Staking90DaysPool
                  walletAddress={account}
                  stakingAmount={stakingAmountFor90Days}
                  setStakingAmount={setStakingAmountFor90Days}
                />

              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </div>


    // <div id="layoutSidenav_content" className="mt-4 container-fluid container p-4">
    //   <div className="row">
    //     <div className="mt-4">
    //       <div className="form-floating mb-3">
    //         <input className="form-control" type="text"
    //           value={walletAddress}
    //           onChange={(e) => {
    //             setWalletAddress(e.target.value);
    //           }}
    //         />
    //         <label className="wallet-label" htmlFor="inputEmail">Wallet address</label>
    //       </div>
    //     </div>
    //   </div>


    //   <div className="row">
    //     <div className="col-xs-12 col-md-12 col-lg-12">
    //       <div className="accordion staking-tab-container" id="poolsAccordionList">
    //         <Staking7DaysPool walletAddress={walletAddress} stakingAmount={stakingAmount} setStakingAmount={setStakingAmount} />
    //         <Staking30DaysPool walletAddress={walletAddress} stakingAmount={stakingAmount} setStakingAmount={setStakingAmount} />
    //         <Staking60DaysPool walletAddress={walletAddress} stakingAmount={stakingAmount} setStakingAmount={setStakingAmount} />
    //         <Staking90DaysPool walletAddress={walletAddress} stakingAmount={stakingAmount} setStakingAmount={setStakingAmount} />
    //       </div>
    //     </div>
    //   </div>
    // </div>
  )
}

export default StakingPools
