import React, {createContext, useState} from 'react'

// This handles all the global variables and states
export const GlobalState = createContext()

export const DataProvider = ({children}) =>{
    
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [isDisconnectVisible, setIsDisconnectVisible] = useState(false)

    

    const state = {
        
        isModalVisible: [isModalVisible, setIsModalVisible],
        isDisconnectVisible: [isDisconnectVisible, setIsDisconnectVisible]
    }

    return (
        <GlobalState.Provider value={state}>
            {children}
        </GlobalState.Provider>
    )
}
