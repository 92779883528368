import React, { useState, useEffect } from 'react';
import { Card, Button, Spin } from 'antd';
import EventEmitter from '../../../utils/EventEmitter'
import * as web3Service from '../services/staking.service'
import { LoadingOutlined } from '@ant-design/icons'
import NumberFormat from 'react-number-format'

export default function TokenDetailsSection(props) {

    const spinnerIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />

    const { walletAddress, contractAddress } = props

    const [userEverEarnTokenBalance, setUserEverEarnTokenBalance] = useState(0.0)
    const [isUserEverEarnTokenBalanceLoading, setIsUserEverEarnTokenBalanceLoading] = useState(false)

    const [userStakeAmount, setUserStakeAmount] = useState(0.0)
    const [isUserStakeAmountLoading, setIsUserStakeAmountLoading] = useState(false)

    const [totalStakeAmount, setTotalStakeAmount] = useState(0.0)
    const [isTotalStakeAmountLoading, setIsTotalStakeAmountLoading] = useState(false)

    const [userBusdAmount, setUserBusdAmount] = useState(0.0)
    const [isUserBusdAmountLoading, setIsUserBusdAmountLoading] = useState(false)
    const [logs, setLogs] = useState(null)

    useEffect(() => {
        const onNewLogs = (eventData) => {
            setLogs(eventData)
        }

        const listener = EventEmitter.addListener('refreshEvent', onNewLogs)

        return () => {
            listener.remove()
        }
    }, [])

    const loadEverEarnBalance = async () => {
        setIsUserEverEarnTokenBalanceLoading(true)
        const tokenBalance = await web3Service.getEverEarnBalanceByWalletAddress(walletAddress)
        setIsUserEverEarnTokenBalanceLoading(false)
        setUserEverEarnTokenBalance(tokenBalance)
    }

    const getUserStakeAmount = async () => {
        setIsUserStakeAmountLoading(true)
        const stakedAmount = await web3Service.getUserStakeAmountByWalletAddress(contractAddress, walletAddress)
        setUserStakeAmount(stakedAmount)
        setIsUserStakeAmountLoading(false)
    }

    const getTotalStakeAmount = async () => {
        setIsTotalStakeAmountLoading(true)
        const totalStakedAmount = await web3Service.getTotalStakeValueByStakingContractAddress(contractAddress)
        setTotalStakeAmount(totalStakedAmount)
        setIsTotalStakeAmountLoading(false)
    }

    const getUserBusdValue = async () => {
        setIsUserBusdAmountLoading(true)
        const busdBalance = await web3Service.getUserBUSDValueByTokenWalletAddress(walletAddress)
        setUserBusdAmount(busdBalance)
        setIsUserBusdAmountLoading(false)
    }

    useEffect(() => {
        loadEverEarnBalance()
        getUserStakeAmount()
        getTotalStakeAmount()
        getUserBusdValue()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [walletAddress])

    useEffect(() => {
        loadEverEarnBalance()
        getUserStakeAmount()
        getTotalStakeAmount()
        getUserBusdValue()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [logs])

    return (
        <div>
            <div className="row">
                <div className="col-xl-3 col-md-3 mt-1">
                    <Card style={{ border: 'none' }} className="staking-detail-card mt-2">
                        <div className="row">
                            <h6 className="token-detail-box">EverEarn Balance</h6>
                            <hr style={{ borderBottom: '1px solid white' }} />
                        </div>

                        <div className="row">
                            {
                                isUserEverEarnTokenBalanceLoading ? (<div>
                                    <Spin indicator={spinnerIcon} />
                                </div>)
                                    :
                                    (
                                        <span className="text-light staking-widget-values">
                                            <NumberFormat
                                                value={userEverEarnTokenBalance ? userEverEarnTokenBalance : 0.00}
                                                displayType="text"
                                                decimalScale={2}
                                                thousandSeparator={true}
                                            />

                                        </span>

                                    )
                            }

                        </div>
                    </Card>
                </div>
                <div className="col-xl-3 col-md-3 mt-1">
                    <Card style={{ border: 'none' }} className="staking-detail-card mt-2">
                        <div className="row">
                            <h6 className="token-detail-box">Your Total BUSD Staked</h6>
                            <hr style={{ borderBottom: '1px solid white' }} />
                        </div>

                        <div className="row">
                            {
                                isUserStakeAmountLoading ? (<div>
                                    <Spin indicator={spinnerIcon} />
                                </div>)
                                    :
                                    (
                                        <span className="text-light staking-widget-values">
                                            <NumberFormat
                                                value={userStakeAmount ? userStakeAmount : 0.0000}
                                                displayType="text"
                                                decimalScale={3}
                                                thousandSeparator={true}
                                            />

                                        </span>

                                    )
                            }
                        </div>
                    </Card>
                </div>
                <div className="col-xl-3 col-md-3">
                    <Card style={{ border: 'none' }} className="staking-detail-card mt-2">
                        <div className="row">
                            <h6 className="token-detail-box">Total BUSD Staked (All Investors)</h6>
                            <hr style={{ borderBottom: '1px solid white' }} />
                        </div>

                        <div className="row">
                            {
                                isTotalStakeAmountLoading ? (<div>
                                    <Spin indicator={spinnerIcon} />
                                </div>)
                                    :
                                    (
                                        <span className="text-light staking-widget-values">
                                            <NumberFormat
                                                value={totalStakeAmount ? totalStakeAmount : 0.0000}
                                                displayType="text"
                                                decimalScale={3}
                                                thousandSeparator={true}
                                            />

                                        </span>

                                    )
                            }
                        </div>
                    </Card>
                </div>

                <div className="col-xl-3 col-md-3">
                    <Card style={{ border: 'none' }} className="staking-detail-card mt-2">
                        <div className="row">
                            <h6 className="token-detail-box">BUSD Balance</h6>
                            <hr style={{ borderBottom: '1px solid white' }} />
                        </div>

                        <div className="row">
                            {
                                isUserBusdAmountLoading ? (<div>
                                    <Spin indicator={spinnerIcon} />
                                </div>)
                                    :
                                    (
                                        <span className="text-light staking-widget-values">
                                            <NumberFormat
                                                value={userBusdAmount ? userBusdAmount : 0.0000}
                                                displayType="text"
                                                decimalScale={3}
                                                thousandSeparator={true}
                                            />

                                        </span>

                                    )
                            }
                        </div>
                    </Card>

                </div>
            </div>
        </div>
    );
}
