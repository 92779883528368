import { useEffect, useState } from 'react'
import { useWeb3React } from '@web3-react/core'
import { InjectedConnector } from '@web3-react/injected-connector'
import { WalletConnectConnector } from '@web3-react/walletconnect-connector'

function Web3LoginProvider({ children }) {
  const { active: networkActive, error: networkError, activate: activateNetwork } = useWeb3React()
  const [loaded, setLoaded] = useState(false)
  const injected = new InjectedConnector({ supportedChainIds: [56, 97, 1] })

  const POLLING_INTERVAL = 3000
  const wcConnector = new WalletConnectConnector({
    rpc: {
      56: "https://bsc-dataseed1.binance.org"
    },
    chainId: 56,
    network: "binance",
    qrcode: true,
    qrcodeModalOptions: {
      mobileLinks: [
        "metamask",
        "trust"
      ]
    },
    pollingInterval: POLLING_INTERVAL
  })

  wcConnector.networkId = 56

  useEffect(() => {

    const userData = window.localStorage.getItem('userData')
    if (userData) {
      const userDataObj = JSON.parse(userData)
      if (userDataObj.provider === 'injected' && userDataObj.isLoggedIn) {
        injected
          .isAuthorized()
          .then((isAuthorized) => {
            setLoaded(true)
            if (isAuthorized && !networkActive && !networkError) {
              activateNetwork(injected)
            }
          })
          .catch(() => {
            setLoaded(true)
          })
      }

      if (userDataObj.provider === 'walletconnect' && userDataObj.isLoggedIn) {
        const walletConnectData = window.localStorage.getItem('walletconnect')
        if (!networkActive && !networkError && walletConnectData) {
          activateNetwork(wcConnector)
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activateNetwork, networkActive, networkError])
  if (loaded) {
    return children
  }
  return children
}

export default Web3LoginProvider