import React from 'react'
import { Row, Col } from 'reactstrap'
import { Collapse, Space } from 'antd'

const { Panel } = Collapse;

function Faq() {


  return (

    <div className="container mt-4 p-4">
      <Row>
        <Col sm='12' md='12' lg='12' className="text-start">
          <div className="faq-container-section">


            <Collapse collapsible="header">
              <Panel header="How often are rewards paid out?" key="1">
                <p>Rewards are set to pay out hourly or once they hit the minimum payout level, whichever comes first.</p>
              </Panel>
            </Collapse>
            <Collapse>
              <Panel header="How do I claim my rewards?" key="1">
                <p>
                  If you don't want to wait for the automatic payout, you can always click the "Claim Now" button on the dashboard. Otherwise, sit tight and let the rewards roll in.</p>
              </Panel>
            </Collapse>

            <Collapse>
              <Panel header="Do I pay taxes if I transfer Earn from one wallet to another?" key="1">
                <p>
                  yes, 15%
                </p>
              </Panel>
            </Collapse>

            <Collapse>
              <Panel header="We'll have a marketplace built into the EverEarn ecosystem?" key="1">
                <p>
                  Yes
                </p>
              </Panel>
            </Collapse>

          </div>
        </Col>
      </Row>
    </div>
  )
}

export default Faq
