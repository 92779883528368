/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react"
import * as web3Service from '../blockchain/web3.service'

export const useTokenCirculationSupply = (props) => {

  const { contractAddress } = props
  const [circulationSupply, setCirculationSupply] = useState(0.0)
  const [isLoading, setLoading] = useState(false)

  useEffect(async () => {

    try {
      setLoading(true)
      const circulationSupplyResponse = await web3Service.getTokenCirculationSupplyByContractAddress(contractAddress)
      setCirculationSupply(circulationSupplyResponse)
    } catch (error) {
      setCirculationSupply(0.0)
      console.error('error while calculation token circulation supply ', error || '')
    } finally {
      setLoading(false)
    }

  }, [])
  return { circulationSupply, isLoading }
}

export default useTokenCirculationSupply

