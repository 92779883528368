export const blackList = [
  {
    "name": "",
    "symbol": "",
    "token_address": "0x0b7DC561777842d55163e0F48886295aad1359b9"
  },
  {
    "name": "",
    "symbol": "",
    "token_address": "0xD08F7B01fdd26928DCDC956610A5332f17b3Ea11"
  },
  {
    "name": "",
    "symbol": "",
    "token_address": "0x1F040f15Ab15B7e0DFaC935873fAdBe43d015535"
  },
  {
    "name": "",
    "symbol": "",
    "token_address": "0xe8eeC309AEAB8018797233b54ce8DeD8Cc05aeAd"
  },
  {
    "name": "",
    "symbol": "",
    "token_address": "0xCaEE79616CFfEB53FddA5792742a5c084f879dec"
  },
  {
    "name": "",
    "symbol": "",
    "token_address": "0xdC4cB4C3587532409A4545AA79A15d967BED1C08"
  },
  {
    "name": "",
    "symbol": "",
    "token_address": "0x64F2C2AA04755507a2eCD22ceB8C475B7A750A3a"
  },
  {
    "name": "",
    "symbol": "",
    "token_address": "0x9028418bbf045fcFE85A3d44aB8054712d98872b"
  },
  {
    "name": "",
    "symbol": "",
    "token_address": "0xb0A2416fD12711cbcFAFB429031c0f7037Fab970"
  },
  {
    "name": "",
    "symbol": "",
    "token_address": "0xEadE8e37700a34b196142d9962858D2a137e6EB8"
  },
  {
    "name": "",
    "symbol": "",
    "token_address": "0xcFa18EE4C639bC1F058a25Da5dBa26D8a4C895be"
  },
  {
    "name": "",
    "symbol": "",
    "token_address": "0xFD4c532a8c17Bd326c2dc63b88d49306ce27F80B"
  },
  {
    "name": "",
    "symbol": "",
    "token_address": "0x5D104F4C1a023ddcCaBAf53652175Cc387B2827f"
  },
  {
    "name": "",
    "symbol": "",
    "token_address": "0x7FFb8374f9ca3eae7a0D38f5Eec3A8aa8bdd453a"
  },
  {
    "name": "",
    "symbol": "",
    "token_address": "0x16ad86969B6F22a9DD164e1Db92B44C900b1c77D"
  }
]