import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Web3ReactProvider } from "@web3-react/core"
import { Web3Provider } from "@ethersproject/providers"

import Web3LoginProvider from './providers/Web3LoginProvider';
import { MoralisProvider } from 'react-moralis';
import { configs } from './blockchain/web3.config';
const getLibrary = (provider) => {
  const library = new Web3Provider(provider)
  library.pollingInterval = 12000
  return library
}

const { nftContractConfig } = configs
const moralisServerURL = nftContractConfig.moralisServerUrl
const moralisAppId = nftContractConfig.moralisApplicationId

ReactDOM.render(
  <React.StrictMode>
    <Web3ReactProvider getLibrary={getLibrary}>
      <Web3LoginProvider>
        <MoralisProvider appId={moralisAppId} serverUrl={moralisServerURL}>
          <App />
        </MoralisProvider>
      </Web3LoginProvider>
    </Web3ReactProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
