/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react"
import * as web3Service from "../blockchain/web3.service"
import { DateTime } from "luxon"
import { configs } from "../blockchain/web3.config"

export const useUserTokenInfo = (props) => {

  const { userWalletAddress } = props
  const [tokenHoldings, setTokenHolding] = useState(0.0)
  const [tokenHoldingValueInUSD, setTokenHoldingValueInUSD] = useState(0.0)
  const [busdHoldings, setBusdHoldings] = useState(0.0)
  const [busdEarning, setBusdEarning] = useState(0.0)
  const [tokenRewardsAmount, setTokenRewardsAmount] = useState(0.0)
  const [tokenRewardsLastPayoutTime, setTokenRewardsLastPayoutTime] = useState('')
  const [claimableRewardsAmount, setClaimableRewardsAmount] = useState(0.0)
  const [claimableRewardsAmountInUSD, setClaimableRewardsAmountInUSD] = useState(0.0)
  const [isLoading, setLoading] = useState(false)


  useEffect(async () => {
    try {
      if (userWalletAddress) {
        setLoading(true)
        const { web3Config } = configs
        const primaryTokenAddress = web3Config.contractWallets.main
        //get user token holdings by address
        const userTokenBalance = await web3Service.getTokenBalanceByUserWalletAddress(userWalletAddress)
        setTokenHolding(userTokenBalance)

        //get user token holdings by address
        const tokenPrice = await web3Service.getERC20TokenPriceInUSDByAddress(primaryTokenAddress)
        const userTokenValueInUSD = tokenPrice * userTokenBalance
        setTokenHoldingValueInUSD(userTokenValueInUSD)

        const busdTokenAddress = web3Config.pricingAddress.busdTokenAddress
        const busdTokenBalanceResponse = await web3Service.getERC20TokenBalanceByWalletAddress(busdTokenAddress, userWalletAddress)
        setBusdHoldings(busdTokenBalanceResponse)

        const dividendEarning = await web3Service.getUserDividendsEarningByWalletAddress(userWalletAddress)
        setBusdEarning(dividendEarning)

        const unpaidEarning = await web3Service.getUserUnpaidDividendsEarningByWalletAddress(userWalletAddress)
        setClaimableRewardsAmount(unpaidEarning)
        //since usd price of BUSD ~1 
        setClaimableRewardsAmountInUSD(unpaidEarning)

        //get user token holdings by address
        const { totalPaidAmount, lastPaidAtTimestamp } = await web3Service.getTokenDividendInfoByUserWalletAddress(userWalletAddress)
        if (lastPaidAtTimestamp) {
          const dateTime = DateTime.fromSeconds(lastPaidAtTimestamp)
          const formattedTime = dateTime.toFormat('yyyy LLL dd - h:mm a')
          setTokenRewardsLastPayoutTime(formattedTime)
        } else {
          setTokenRewardsLastPayoutTime('N/A')
        }
        setTokenRewardsAmount(totalPaidAmount)
        setLoading(false)
      }

    } catch (error) {
      setTokenHolding(0.0)
      setBusdEarning(0.0)
      setBusdHoldings(0.0)
      setTokenHoldingValueInUSD(0.0)
      setClaimableRewardsAmount(0.0)
      setClaimableRewardsAmountInUSD(0.0)
      setLoading(false)
      console.error('error while fetching user token info ', error.message || '')
    }

  }, [userWalletAddress])


  return {
    tokenHoldings,
    tokenHoldingValueInUSD,
    busdHoldings,
    busdEarning,
    tokenRewardsAmount,
    tokenRewardsLastPayoutTime,
    claimableRewardsAmount,
    claimableRewardsAmountInUSD,
    isLoading
  }
}

export default useUserTokenInfo

