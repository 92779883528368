import React from 'react';
import { Row, Col } from 'reactstrap';
import { Card } from 'antd';
import './General.css';

function ToDo() {
	const toDoList = [
		{ name: 'LiveCoinWatch', link: 'https://www.livecoinwatch.com/price/EverEarn-__EARN' },
		{ name: 'CoinVote', link: 'https://coinvote.cc/coin/EverEarn' },
		{ name: 'Freshcoins', link: 'https://www.freshcoins.io/coins/everearn' },
		{ name: 'Coinxhigh', link: 'https://coinxhigh.com/todays-best-cryptocurrencies/everearn' },
		{ name: 'CoinTopList', link: 'https://cointoplist.net/coin/everearn' },
		{ name: 'MyCoinVote', link: 'https://www.mycoinvote.com/EverEarn' },
		{ name: 'Gemfinder', link: 'https://gemfinder.cc/gem/6909' },
		{ name: 'GemHunters', link: 'https://gemhunters.net/coin/everearn/' },
		{ name: 'CoinHunt', link: 'https://coinhunt.cc/coin/1145977047' },
		{ name: 'CoinMoonHunt', link: 'https://coinmoonhunt.com/coin/EverEarn' },
		{ name: 'CoinHunters', link: 'https://coinhunters.cc/tokens/EverEarn' },
		{ name: 'RugFreeCoins', link: 'https://www.rugfreecoins.com/details/7666' },
		{ name: 'CoinScope', link: 'https://www.coinscope.co/coin/1-earn' },
		{ name: 'CoinDiscovery', link: 'https://coindiscovery.app/coin/everearn' },
		{ name: 'CoinGods', link: 'https://coinsgods.com/coin/3146' },
		{ name: 'CoinMooner', link: 'https://coinmooner.com/coin/11042' },
		{ name: 'EarlyCoins', link: 'https://www.earlycoins.io/coin-details/620467b7501b1d32dd5c569f' },
		{ name: 'RisingTokens', link: 'https://risingtokens.com/coin/everearn/' }
	];

	return (
		<div className="container mt-4 p-4">
			<Row>
                <h2 className='todo-header'>Your daily voting list</h2>
				<div className=" row justify-content-center align-items-center ">
					{toDoList.map((list) => (
						<Col sm="12" md="6" lg="3">
							<a href={list.link} target={'_blank'}>
								{/* <Card className="todo-card">
									<div className="todo-inner">{list.name}</div>
								</Card> */}
								<Card style={{ border: 'none' }} className="customcard mt-3 text-center">
									<div className="row mt-3">
										<div className="todo-inner">{list.name}</div>
										{/* <h6 className="text-muted">{list.name}</h6> */}
									</div>
								</Card>
							</a>
						</Col>
					))}
				</div>
			</Row>
		</div>
	);
}

export default ToDo;
