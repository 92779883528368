/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react"
import * as web3Service from '../blockchain/web3.service'

export const useBuyAndSellFee = (props) => {

  const { contractAddress } = props
  const [buyAndSellFees, setBuyAndSellFees] = useState(null)
  const [isLoading, setLoading] = useState(false)

  useEffect(async () => {

    try {
      setLoading(true)
      const buyAndSellFeeResponse = await web3Service.getBuyAndSellFee(contractAddress)
      setBuyAndSellFees(buyAndSellFeeResponse)
    } catch (error) {
      setBuyAndSellFees(null)
      console.error('error while fetching buy and sell fee ', error || '')
    } finally {
      setLoading(false)
    }

  }, [])
  return { buyAndSellFees, isLoading }
}

export default useBuyAndSellFee

