import { stakingConfigs } from './staking.config'
import { ethers, utils } from "ethers";


const setTokenDecimals = (number, decimals) => {
  number = number.toString();
  let numberAbs = number.split('.')[0]
  let numberDecimals = number.split('.')[1] ? number.split('.')[1] : '';
  while (numberDecimals.length < decimals) {
    numberDecimals += "0";
  }
  return numberAbs + numberDecimals;
}

export const getEverEarnBalanceByWalletAddress = async (walletAddress) => {

  try {

    if (walletAddress) {
      const everEarnTokenABI = JSON.parse(stakingConfigs.erc20TokenContractAbi);
      const everEarnTokenAddress = stakingConfigs.tokenAddress;
      //get current metamask provider
      const provider = new ethers.providers.JsonRpcProvider(stakingConfigs.webRpcHttpNode, { name: 'binance', chainId: 56 });
      const everEarnTokenInstance = new ethers.Contract(everEarnTokenAddress, everEarnTokenABI, provider);
      const tokeBalance = await everEarnTokenInstance.balanceOf(walletAddress)

      const tokenDecimals = await everEarnTokenInstance.decimals()
      const tokenDecimalInt = parseInt(tokenDecimals.toString())

      const tokenBalanceInt = parseInt(tokeBalance.toString())
      const actualTokenBalance = tokenBalanceInt / (10 ** tokenDecimalInt)
      return actualTokenBalance

    } else {
      return 0.0
    }

  } catch (error) {
    console.error("ERROR : error while fetching user ever earn balance", error)
    return 0.0
  }

}

export const getUserStakeAmountByWalletAddress = async (stakingContractAddress, walletAddress) => {

  try {

    if (walletAddress) {
      const stakingContractABI = JSON.parse(stakingConfigs.stakingContractAbi);
      //get current metamask provider
      const provider = new ethers.providers.JsonRpcProvider(stakingConfigs.webRpcHttpNode, { name: 'binance', chainId: 56 });
      const stakingContractInstance = new ethers.Contract(stakingContractAddress, stakingContractABI, provider);
      const stakingDetails = await stakingContractInstance.stakeDetails(walletAddress)

      const stakingAmountStr = stakingDetails[0].toString()
      const stakingAmountInt = parseInt(stakingAmountStr)
      const actualStakingAmount = stakingAmountInt / 10 ** 18
      return actualStakingAmount

    } else {
      return 0.0
    }

  } catch (error) {
    console.error("ERROR : error while fetching user staked amount", error)
    return 0.0
  }

}

export const getTotalStakeValueByStakingContractAddress = async (stakingContractAddress) => {

  try {
    const stakingContractABI = JSON.parse(stakingConfigs.stakingContractAbi);
    //get current metamask provider
    const provider = new ethers.providers.JsonRpcProvider(stakingConfigs.webRpcHttpNode, { name: 'binance', chainId: 56 });
    const stakingContractInstance = new ethers.Contract(stakingContractAddress, stakingContractABI, provider);
    const totalStakedStr = await stakingContractInstance.totalStaking()

    const totalStakedInt = parseInt(totalStakedStr.toString())
    const actualTotalStaked = totalStakedInt / (10 ** 18)
    return actualTotalStaked

  } catch (error) {
    console.error("ERROR : error while fetching total staked value in contract", error)
    return 0.0
  }

}

export const getUserBUSDValueByTokenWalletAddress = async (walletAddress) => {

  try {

    if (walletAddress) {
      const erc20ContractAbi = JSON.parse(stakingConfigs.erc20TokenContractAbi);
      const busdTokenAddress = stakingConfigs.busdTokenAddress
      //get current metamask provider
      const provider = new ethers.providers.JsonRpcProvider(stakingConfigs.webRpcHttpNode, { name: 'binance', chainId: 56 });
      const erc20ContractInstance = new ethers.Contract(busdTokenAddress, erc20ContractAbi, provider);
      const busdTokenBalance = await erc20ContractInstance.balanceOf(walletAddress)

      const busdTokenBalanceInt = parseInt(busdTokenBalance.toString())
      const actualBusdTokenBalance = busdTokenBalanceInt / (10 ** 18)
      return actualBusdTokenBalance
    } else {
      return 0.0
    }

  } catch (error) {
    console.error("ERROR : error while fetching total staked value in contract", error)
    return 0.0
  }

}

export const calculateAprDataUsingContractAddress = async (stakingContractAddress) => {
  try {
    const stakingContractABI = JSON.parse(stakingConfigs.stakingContractAbi);
    //get current metamask provider
    const provider = new ethers.providers.JsonRpcProvider(stakingConfigs.webRpcHttpNode, { name: 'binance', chainId: 56 });
    const stakingContractInstance = new ethers.Contract(stakingContractAddress, stakingContractABI, provider);

    const useApyValue = await stakingContractInstance.useApy()

    if (useApyValue) {
      const stakingAmountInWei = utils.parseEther('100')
      const currentAprValueResponse = await stakingContractInstance.currentApr()
      const currentAprStr = currentAprValueResponse.toString()

      const calculatedAprByRewards = await stakingContractInstance.calculatRewardByAPY(stakingAmountInWei.toString(), currentAprStr, '365')
      const calculatedAprInt = parseInt(calculatedAprByRewards.toString())
      const actualCalculatedApr = calculatedAprInt / (10 ** 18)

      const rewardPerShare = await stakingContractInstance.rewardPerShare()
      const actualRewardPerShare = parseInt(rewardPerShare.toString()) / (10 ** 18)
      const aprFromSecondMethod = 100 * actualRewardPerShare

      if (aprFromSecondMethod >= actualCalculatedApr) {
        return actualCalculatedApr
      } else {
        return aprFromSecondMethod
      }
    } else {
      const stakingAmount = 100;
      const currentAprResponse = await stakingContractInstance.currentApr()
      const currentAprInt = parseInt(currentAprResponse.toString())


      const aprMethod1 = stakingAmount * currentAprInt / 10000

      const rewardPerShare = await stakingContractInstance.rewardPerShare()
      const actualRewardPerShare = parseInt(rewardPerShare.toString()) / (10 ** 18)

      const aprFromSecondMethod = stakingAmount * actualRewardPerShare

      if (aprFromSecondMethod >= aprMethod1) {
        return aprMethod1
      } else {
        return aprFromSecondMethod
      }
    }


  } catch (error) {
    console.error("ERROR : error while fetching apr data using contract", error)
    return 0.0
  }
}


export const calculateRemainingPoolValueByContractAddress = async (stakingContractAddress) => {
  try {
    const stakingContractABI = JSON.parse(stakingConfigs.stakingContractAbi);
    //get current metamask provider
    const provider = new ethers.providers.JsonRpcProvider(stakingConfigs.webRpcHttpNode, { name: 'binance', chainId: 56 });
    const stakingContractInstance = new ethers.Contract(stakingContractAddress, stakingContractABI, provider);

    const maxStakedTokenResponse = await stakingContractInstance.maxStakeTokensInThePool()
    const currentStakedTokenResponse = await stakingContractInstance.currentStakeInThePool()
    const remainingTokensInPool = parseInt(maxStakedTokenResponse.toString()) - parseInt(currentStakedTokenResponse.toString())
    const actualRemainingTokensInPool = remainingTokensInPool / (10 ** 18)
    return actualRemainingTokensInPool

  } catch (error) {
    console.error("ERROR : error while fetching remaining pool tokens", error)
    return 0.0
  }
}

export const getBnbPrice = async (blockNumber = 'latest') => {

  const contractAbi = JSON.parse(stakingConfigs.pancakeSwapContractAbi);
  const pcsContractAddress = stakingConfigs.pcsRouterAddress;

  const provider = new ethers.providers.JsonRpcProvider(stakingConfigs.webRpcHttpNode, { name: 'binance', chainId: 56 });
  const pcsContractInstance = new ethers.Contract(pcsContractAddress, contractAbi, provider);
  let bnbToSell = ethers.utils.parseEther("1.0")
  let amountOut
  try {
    amountOut = await pcsContractInstance.getAmountsOut(bnbToSell.toBigInt(), [
      stakingConfigs.wBnbAddress,
      stakingConfigs.usdtTokenAddress], { blockTag: blockNumber });
    amountOut = ethers.utils.formatEther(amountOut[1])
  } catch (error) {
    console.log("Error while fetching bnb price", error)
    amountOut = '0';
  }
  return parseFloat(amountOut);
}

export const getTokenPriceInUSDByAddress = async (tokenAddress, blockNumber = 'latest') => {

  try {

    const contractAbi = JSON.parse(stakingConfigs.pancakeSwapContractAbi);
    const pcsContractAddress = stakingConfigs.pcsRouterAddress;

    const provider = new ethers.providers.JsonRpcProvider(stakingConfigs.webRpcHttpNode, { name: 'binance', chainId: 56 });
    const pcsContractInstance = new ethers.Contract(pcsContractAddress, contractAbi, provider);

    const erc20ContractAbi = JSON.parse(stakingConfigs.erc20TokenContractAbi);
    const erc20ContractInstance = new ethers.Contract(tokenAddress, erc20ContractAbi, provider);

    const tokenDecimalsResponse = await erc20ContractInstance.decimals()
    const tokenDecimals = parseInt(tokenDecimalsResponse.toString())
    const tokenToSell = setTokenDecimals(1, tokenDecimals);
    let tokenPrice = 0;
    tokenPrice = await pcsContractInstance.getAmountsOut(tokenToSell, [tokenAddress, stakingConfigs.wBnbAddress], { blockTag: blockNumber });
    tokenPrice = ethers.utils.formatEther(tokenPrice[1]);

    const bnbPriceInUSD = await getBnbPrice()
    const tokenPriceInUSD = bnbPriceInUSD * tokenPrice;
    return tokenPriceInUSD

  } catch (error) {
    console.log("Error while fetching token price in USD", error)
    return 0.0
  }

}

export const getUserCompleteStakeDetailsByWalletAddress = async (stakingContractAddress, walletAddress) => {

  try {

    if (walletAddress) {
      const stakingContractABI = JSON.parse(stakingConfigs.stakingContractAbi);
      //get current metamask provider
      const provider = new ethers.providers.JsonRpcProvider(stakingConfigs.webRpcHttpNode, { name: 'binance', chainId: 56 });
      const stakingContractInstance = new ethers.Contract(stakingContractAddress, stakingContractABI, provider);
      const stakingDetails = await stakingContractInstance.stakeDetails(walletAddress)
      return stakingDetails

    } else {
      return null
    }

  } catch (error) {
    console.error("ERROR : error while fetching compete user stake details ", error)
    return null
  }

}