import { useEffect, useRef } from "react";
import Jazzicon from "@metamask/jazzicon";
import styled from "@emotion/styled";

const StyledIdenticon = styled.div`
  border-radius: 50%;
  background-color: black;
`;

export default function UserIdenticon(props) {

  const { size, walletAddress, customCss } = props
  const ref = useRef();

  useEffect(() => {
    if (walletAddress && ref.current) {
      ref.current.innerHTML = "";
      ref.current.appendChild(Jazzicon(size ? parseInt(size) : 35, parseInt(walletAddress.slice(2, 10), 16)));
    }
  }, [walletAddress, size]);

  return <StyledIdenticon ref={ref} style={customCss} />;
}
