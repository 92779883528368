import React, { useState, useEffect } from 'react'
import * as web3Service from '../services/staking.service'
import EventEmitter from '../../../utils/EventEmitter'
import { Card } from 'reactstrap'
import NumberFormat from 'react-number-format'
import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'

export default function StakingApyWidget(props) {

  const spinnerIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />
  const { contractAddress, days } = props

  const [isRemainingPoolValueLoading, setIsRemainingPoolValueLoading] = useState(false)
  const [remainingPoolValue, setRemainingPoolValue] = useState(0)
  const [isAprDataLoading, setIsAprDataLoading] = useState(false)
  const [aprValue, setAprValue] = useState(0.0)


  const [logs, setLogs] = useState(null)

  useEffect(() => {
    const onNewLogs = (eventData) => {
      setLogs(eventData)
    }

    const listener = EventEmitter.addListener('refreshEvent', onNewLogs)

    return () => {
      listener.remove()
    }
  }, [])


  const loadAprData = async () => {
    setIsAprDataLoading(true)
    const aprValue = await web3Service.calculateAprDataUsingContractAddress(contractAddress)
    setAprValue(aprValue.toFixed(2))
    setIsAprDataLoading(false)
  }

  const getRemainingPoolValue = async () => {
    setIsRemainingPoolValueLoading(true)
    const remainingPoolValue = await web3Service.calculateRemainingPoolValueByContractAddress(contractAddress)
    setRemainingPoolValue(remainingPoolValue || 0.0)
    setIsRemainingPoolValueLoading(false)
  }

  useEffect(() => {
    loadAprData()
    getRemainingPoolValue()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    loadAprData()
    getRemainingPoolValue()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logs])


  return (
    <div>

      <div className="apy-widget-container">
        <div className="apy-widget-header">
          <div className="header-title">
            Remaining BUSD value in the pool
          </div>
          <div className="apy-remaining-pool-value">
            <NumberFormat
              value={remainingPoolValue ? remainingPoolValue : 0.0}
              displayType="text"
              decimalScale={3}
              thousandSeparator={true}
            />
          </div>
        </div>

        <div className="apy-widget-middle-section">
          <div className="holding-period">{days}</div>
          <div className="holding-period-unit">Days</div>
        </div>

        <div className="footer-apy-percentage">
          {
            isAprDataLoading ?
              (<div>
                <Spin indicator={spinnerIcon} />
              </div>) :
              (
                <div className="apy-value">
                  <NumberFormat
                    value={aprValue ? Math.floor(aprValue) : 0.0}
                    displayType="text"
                    suffix=" %"
                    thousandSeparator={false}
                  />

                </div>
              )
          }

        </div>
      </div>
      {/* <Card style={{ border: 'none' }} className='staking-pool-card'>
        <div>
          <div className="row">
            <h6 className="text-light-green pool-header">Remaining BUSD value in the pool --</h6>
            <div className="pool-value justify-content-center align-items-center">{n4.format(remainingPoolValue)}</div>
            <hr style={{ borderBottom: '1px solid white' }} />
          </div>
          <div className="card-header text-center">
            <span className="custom-large-text">{days} Days</span>
          </div>
        </div>
      </Card> */}
    </div>
  )
}
