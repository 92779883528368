import React, { useContext } from 'react'
import { Routes, Route } from 'react-router-dom'
import Dashboard from './Dashboard/Dashboard'
import Calculator from './Calculator/Calculator'
import NftMint from './NftMint/NftMint'
import Staking from './Staking/Staking'
import NFTTracker from './NFTTracker/NFTTracker'
import NFTDetailsPage from './NFTTracker/NFTDetailsPage'
import WalletTracker from './WalletTracker/WalletTracker'
import Faq from './General/Faq'
import ToDo from './General/ToDo'
import EverSwap from './General/EverSwap'

function Pages() {
    return (
        <Routes>
            <Route path="/" exact element={<Dashboard />} />
            <Route path="/calculator" exact element={<Calculator />} />
            <Route path="/nft-mint" exact element={<NftMint />} />
            <Route path="/staking" exact element={<Staking />} />
            <Route path="/nft-tracker" exact element={<NFTTracker />} />
            <Route path="/nft-details" exact element={<NFTDetailsPage />} />
            <Route path="/wallet-tracker" exact element={<WalletTracker />} />
            <Route path="/faq" exact element={<Faq />} />
            <Route path="/to-do" exact element={<ToDo />} />
            <Route path="/ever-swap" exact element={<EverSwap />} />
        </Routes>
    )
}

export default Pages
