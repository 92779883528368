import React from 'react'
import { stakingConfigs } from './services/staking.config'
import TokenDetailsSection from './components/TokenDetailsSection'
import WithdrawAndExitButton from './components/WithdrawAndExitButton'
import EarlyWithdrawButton from './components/EarlyWithdrawButton'
import StakingApyWidget from './components/StakingApyWidget'
import StakingInput from './components/StakingInput'
import UserStakingDetails from './components/UserStakingDetails'

export default function Staking60DaysPool(props) {

  const { walletAddress, setStakingAmount, stakingAmount } = props

  return (
    <>
      <div className="accordion-item" style={{ marginTop: '30px' }}>
        <h2 className="accordion-header staking-accordion-header" id="headingPoolFor60Days">
          <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#poolFor60Days" aria-expanded="true" aria-controls="poolFor60Days">
            60 Days Pool
          </button>
        </h2>
        <div id="poolFor60Days" className="accordion-collapse collapse show" aria-labelledby="headingPoolFor60Days" data-bs-parent="#poolsAccordionList">
          <div className="accordion-body">
            {
              walletAddress ? (
                <TokenDetailsSection walletAddress={walletAddress} contractAddress={stakingConfigs.staking60DaysContractAddress} />
              ) : (
                <></>
              )
            }

            {
              walletAddress ? (
                <div className='row'>
                  <div className="col-xl-6 col-md-6 col-sm-12 mb-3">
                    <WithdrawAndExitButton walletAddress={walletAddress} contractAddress={stakingConfigs.staking60DaysContractAddress} />
                  </div>
                  <div className="col-xl-6 col-md-6 col-sm-12 mb-3">
                    <EarlyWithdrawButton walletAddress={walletAddress} contractAddress={stakingConfigs.staking60DaysContractAddress} />
                  </div>
                </div>
              ) : (
                <></>
              )
            }

            <div className="row" style={{ marginTop: '20px' }}>
              <div className="col-xs-12 col-sm-12 col-md-2 col-lg-2">
                <StakingApyWidget contractAddress={stakingConfigs.staking60DaysContractAddress} days={60} />
              </div>

              <div className="col-xs-12 col-sm-12 col-md-5 col-lg-5">
                <StakingInput
                  setStakingAmount={setStakingAmount}
                  stakingAmount={stakingAmount}
                  stakingDuration={7}
                  walletAddress={walletAddress}
                  contractAddress={stakingConfigs.staking60DaysContractAddress}
                />
              </div>

              <div className="col-xs-12 col-sm-12 col-md-5 col-lg-5">
                <UserStakingDetails
                  walletAddress={walletAddress}
                  contractAddress={stakingConfigs.staking60DaysContractAddress} />
              </div>
            </div>


          </div>
        </div>
      </div>
    </>
  )
}
