import React from 'react';
import { Row, Col } from 'reactstrap';
import { Card } from 'antd';
import './General.css';

function EverSwap() {

	return (
		<div className="container mt-4">
			<Row>
                <Col>
                <div>
                    <h2 className='todo-header'>Buy EverEarn</h2>
                    <div className='ifram-card'>
                    {/* background: '#3e3203',  */}
                        <iframe title="swapper" style={{borderRadius: '10px', border: 'solid 2px #d2a310'}} width="40%" height="675" src="https://www.flooz.trade/embedded/0x47d42E0e864ceFf8C4270F7b6E1f6F91Da45882C/?refId=v3gR0o&chainId=56&backgroundColor=transparent"> </iframe>
                    </div>
                    <div className='ifram-card-mobile'>
                        <iframe title="swapper" style={{borderRadius: '10px', border: 'solid 2px #d2a310'}} width="100%" height="675" src="https://www.flooz.trade/embedded/0x47d42E0e864ceFf8C4270F7b6E1f6F91Da45882C/?refId=v3gR0o&chainId=56&backgroundColor=transparent"> </iframe>
                    </div>
                </div>
                </Col>
			</Row>
		</div>
	);
}

export default EverSwap;
