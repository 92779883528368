/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react"
import * as web3Service from '../blockchain/web3.service'

export const useTokenLiquidityData = (props) => {

  const { contractAddress } = props
  const [liquidityInfo, setLiquidityInfo] = useState(null)
  const [isLoading, setLoading] = useState(false)

  useEffect(async () => {

    try {
      setLoading(true)
      const tokenLiquidityResponse = await web3Service.calculateLiquidityInfoByContractAddress(contractAddress)
      setLiquidityInfo(tokenLiquidityResponse)
    } catch (error) {
      setLiquidityInfo(null)
      console.error('error while calculation token liquidity supply ', error)
    } finally {
      setLoading(false)
    }

  }, [])
  return { liquidityInfo, isLoading }
}

export default useTokenLiquidityData

