import React, { useState, useEffect } from 'react'
import * as web3Service from '../services/staking.service'
import { stakingConfigs } from '../services/staking.config'
import { ethers } from "ethers";
import EventEmitter from '../../../utils/EventEmitter';
import { Button, notification, Input } from 'antd';
import NumberFormat from 'react-number-format'
import { useWeb3React } from '@web3-react/core';

export default function StakingInput(props) {

  const { contractAddress, walletAddress, setStakingAmount, stakingAmount, stakingDuration } = props
  const [tokenBalanceInUSD, setTokenBalanceInUSD] = useState(0.0)
  const [maxStakingPercentage, setMaxStakingPercentage] = useState(0.0)
  const [maximumAllowedStakeAmount, setMaximumAllowedStakeAmount] = useState(0.0)
  const [isActiveStakeButton, setIsActiveStakeButton] = useState(false)
  const [busdTokenAmount, setBusdTokenAmount] = useState(0.0)
  const [isStakingProcessLoading, setIsStakingProcessLoading] = useState(false)
  const [isEverEarnStakingApprovalLoading, setIsEverEarnStakingApprovalLoading] = useState(false)
  const { library } = useWeb3React()

  const getMaxStakingPercentage = async () => {
    try {

      const stakingContractABI = JSON.parse(stakingConfigs.stakingContractAbi);
      //get current metamask provider
      const provider = new ethers.providers.JsonRpcProvider(stakingConfigs.webRpcHttpNode, { name: 'binance', chainId: 56 });
      const stakingContractInstance = new ethers.Contract(contractAddress, stakingContractABI, provider);
      const maxStakingResponse = await stakingContractInstance.maxStakePercentage()
      const maxStakingAmountInt = parseInt(maxStakingResponse.toString())
      setMaxStakingPercentage(maxStakingAmountInt)

    } catch (error) {
      setMaxStakingPercentage(0.0)
      console.error("ERROR while fetching max staking percentage from the contract ", error)
    }
  }

  useEffect(() => {
    const loadEverEarnBalance = async () => {
      const tokenBalance = await web3Service.getEverEarnBalanceByWalletAddress(walletAddress)
      const tokenPrice = await web3Service.getTokenPriceInUSDByAddress(stakingConfigs.tokenAddress)
      const totalTokenPriceInUSD = parseFloat(tokenPrice) * parseFloat(tokenBalance)
      setTokenBalanceInUSD(totalTokenPriceInUSD || 0.0)
    }

    const getUserBusdTokenAmount = async () => {
      const busdTokenAmount = await web3Service.getUserBUSDValueByTokenWalletAddress(walletAddress)
      setBusdTokenAmount(busdTokenAmount)
    }
    loadEverEarnBalance()
    getMaxStakingPercentage()
    getUserBusdTokenAmount()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [walletAddress])

  useEffect(() => {
    const maximumAllowedAmount = (tokenBalanceInUSD / 100) * maxStakingPercentage
    setMaximumAllowedStakeAmount(maximumAllowedAmount || 0)
  }, [tokenBalanceInUSD, maxStakingPercentage])

  const handleStakingAmountOnChange = (userInputAmount) => {
    setIsActiveStakeButton(false)
    if (busdTokenAmount <= 0) {
      notification['warning']({
        message: 'Error',
        description: 'You don\'t have enough BUSD balance to proceed staking',
      });
      return
    }
    if (userInputAmount > busdTokenAmount) {
      notification['warning']({
        message: 'Error',
        description: `Can not exceed current token balance of ${busdTokenAmount}`,
      });
      console.log('Can not exceed current token balance of', busdTokenAmount);
      setStakingAmount(busdTokenAmount)
      return
    }
    setStakingAmount(userInputAmount)
  }

  // const handleSelectRate = (value) => {
  //   if (value) {
  //     //get BUSD value for user
  //     const selectedPercentage = (busdTokenAmount / 100) * value
  //     setStakingAmount(selectedPercentage || 0.00)
  //   }
  //   setIsActiveStakeButton(false)
  // }

  const handleStakeButtonClick = async () => {
    try {
      setIsStakingProcessLoading(true)
      //create the contract instance
      const stakingContractABI = JSON.parse(stakingConfigs.stakingContractAbi);

      //get current metamask provider
      const provider = new ethers.providers.JsonRpcProvider(stakingConfigs.webRpcHttpNode, { name: 'binance', chainId: 56 });
      const stakingContractInstance = new ethers.Contract(contractAddress, stakingContractABI, provider);

      if (!stakingDuration || stakingDuration === '') {

        notification['warning']({
          message: 'Error',
          description: 'Please select valid staking  period',
        });
        setIsStakingProcessLoading(false)
        return
      }

      if (!stakingAmount || stakingAmount <= 0) {

        notification['warning']({
          message: 'Error',
          description: 'Please enter valid staking BUSD amount',
        });
        setIsStakingProcessLoading(false)
        return
      }

      const stakeableAmountInWei = ethers.utils.parseEther(stakingAmount.toString())
      const stakingContractInstanceWithSigner = stakingContractInstance.connect(library.getSigner());
      const stakingReceipt = await stakingContractInstanceWithSigner.newStake(stakeableAmountInWei)
      const result = await stakingReceipt.wait()
      console.log('approvalReceipt', stakingReceipt)
      console.log('result', result)
      setIsStakingProcessLoading(false)
      setStakingAmount(0)
      setIsActiveStakeButton(false)
      EventEmitter.emit('refreshEvent', { isRefresh: true })
      notification['success']({
        message: 'Congratulation !',
        description: 'You have been staked your token successfully',
      });

    } catch (error) {
      setIsStakingProcessLoading(false)
      const errorMessage = error ? error.data.message : 'ERROR: something went wrong while proceeding staking' || 'something went wrong'

      notification['error']({
        message: 'Error !',
        description: errorMessage,
      });
      console.error("ERROR: something went wrong while proceeding staking ", error)

    }
  }

  const handleApproveEverEarnForStaking = async () => {

    try {
      setIsEverEarnStakingApprovalLoading(true)
      //create the contract instance
      const erc20TokenABI = JSON.parse(stakingConfigs.erc20TokenContractAbi);
      const busdTokenAddress = stakingConfigs.busdTokenAddress;
      //get current metamask provider
      const provider = new ethers.providers.JsonRpcProvider(stakingConfigs.webRpcHttpNode, { name: 'binance', chainId: 56 });
      const erc20TokenInstance = new ethers.Contract(busdTokenAddress, erc20TokenABI, provider);

      if (!stakingDuration || stakingDuration === '') {
        notification['warning']({
          message: 'Error !',
          description: 'Please select valid staking  period',
        });
        setIsEverEarnStakingApprovalLoading(false)
        return
      }

      if (!stakingAmount || stakingAmount <= 0) {
        notification['warning']({
          message: 'Error !',
          description: 'Please enter valid staking BUSD amount',
        });
        setIsEverEarnStakingApprovalLoading(false)
        return
      }

      const stakeableAmountInWei = ethers.utils.parseEther(stakingAmount.toString())
      console.log('stakeableAmountInWei', stakeableAmountInWei.toString())

      const erc20ContractInstanceWithSigner = erc20TokenInstance.connect(library.getSigner());
      const approvalReceipt = await erc20ContractInstanceWithSigner.approve(contractAddress, stakeableAmountInWei.toString())
      const result = await approvalReceipt.wait()

      notification['success']({
        message: 'Approval Success',
        description: 'Your token amount has been approved',
      });
      console.log('approvalReceipt', approvalReceipt)
      console.log('result', result)
      setIsEverEarnStakingApprovalLoading(false)
      setIsActiveStakeButton(true)

    } catch (error) {

      notification['error']({
        message: 'Error !',
        description: 'ERROR: something went wrong while approval for staking',
      });
      console.error("ERROR: something went wrong while approval for staking ", error)
      setIsEverEarnStakingApprovalLoading(false)
      setIsActiveStakeButton(false)
    }

  }

  return (
    <div>
      <div className='mb-3'>
        <span className="text-muted">Lock duration and Rate</span><br />
        <span className="text-muted token-stake-amount-info">
          You have ~ {<NumberFormat
            value={tokenBalanceInUSD ? tokenBalanceInUSD : 0.00}
            displayType="text"
            decimalScale={4}
            thousandSeparator={true}
            prefix="~$"

          />} USD worth of tokens. you are only allowed to stake {maxStakingPercentage} % worth of equivalent BUSD tokens. which is roughly ~ {<NumberFormat
            value={maximumAllowedStakeAmount ? maximumAllowedStakeAmount : 0.00}
            displayType="text"
            decimalScale={4}
            thousandSeparator={true}
            prefix="~$"

          />} BUSD
        </span><br />

        <Input
          className="form-control staking-amount-input"
          type="text"
          value={stakingAmount ? stakingAmount : ''}
          onChange={e => handleStakingAmountOnChange(e.target.value)} />
        <div className="mt-3">
          {
            isActiveStakeButton ? (
              <div>
                <Button
                  block={true}
                  onClick={handleStakeButtonClick}
                  size="large"
                  style={{ background: '#efbc00', color: '#000', borderColor: '#efbc00', fontWeight: '600' }}
                  loading={isStakingProcessLoading}
                >
                  Stake BUSD
                </Button>

              </div>
            )
              :
              (
                <div>
                  <Button
                    block={true}
                    onClick={handleApproveEverEarnForStaking}
                    size="large"
                    style={{ background: '#efbc00', color: '#000', borderColor: '#efbc00', fontWeight: '600' }}
                    loading={isEverEarnStakingApprovalLoading}
                  >
                    Approve BUSD Transfer
                  </Button>

                </div>
              )
          }
        </div>
      </div>
    </div>
  )
}
